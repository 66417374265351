import React from "react";
import { Box } from "@mui/material";

export default function Toggler({ darkMode, handleClick }) {
  const transition = "all 250ms ease";

  return (
    <Box
      fontSize={{ xs: "1rem", md: "1.5rem" }}
      sx={{
        cursor: "pointer",
        ":hover": { transform: "translateY(-3px)", transition: transition },
      }}
    >
      {darkMode ? (
        <span onClick={handleClick} aria-label="light-mode" role="img">
          ☀
        </span>
      ) : (
        <span onClick={handleClick} aria-label="dark-mode" role="img">
          ☾
        </span>
      )}
    </Box>
  );
}
